<template>
  <b-container>
    <b-card>
      <validation-observer
        #default="{ invalid }"
      >
        <b-form>
          <b-row style="gap: 16px">
            <b-col md="12">
              <b-form-group
                label="Arquivo"
                label-for="file"
              >

                <validation-provider
                  #default="{ errors }"
                  name="arquivo"
                  rules="xlsx|required"
                >

                  <b-form-file
                    id="file"
                    v-model="file"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Arraste ou selecione um arquivo"
                    drop-placeholder="Arraste o arquivo aqui."
                    browse-text="Procurar"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col>
              <b-button
                variant="primary"
                :disabled="invalid"
                @click="handleClickImportButton"
              >
                Importar
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </b-container>
</template>

<script>
import {
    BForm, BFormFile, BContainer, BRow, BCol, BFormGroup, BCard, BButton,
} from 'bootstrap-vue';

import {
    ValidationProvider,
    ValidationObserver,
} from 'vee-validate';
import CorporateActionPlanService from '@/service/corporate-action-plan';

import '@validations';

export default {
    components: {
        BButton,
        BCard,
        BForm,
        BContainer,
        BFormFile,
        BRow,
        BCol,
        BFormGroup,
        ValidationProvider,
        ValidationObserver,
    },

    data() {
        return {
            file: null,
        };
    },

    methods: {
        async handleClickImportButton() {
            const askResponse = await this.confirmAnAction('Deseja realmete importar o arquivo');

            if (!askResponse) return;

            this.callLoading(true);

            const { status } = await CorporateActionPlanService.import({
                file: this.file,
            });

            this.callLoading(false);
            if (status === 200) {
                this.modalSuccess('Arquivo importado com sucesso');
                return;
            }

            this.modalError('Erro ao importar o arquivo');
        },
    },
};
</script>
