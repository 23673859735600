import Thoth from '../base/thoth';

import store from '@/store';

const username = store.getters['user/user'].data.name;

class CorpActionPlanService extends Thoth {
    import({ file }) {
        const formData = new FormData();

        formData.append('file', file);
        formData.append('username', username);

        return this.fetch({
            method: 'POST',
            data: formData,
            url: '/corporate-action-plan',
        });
    }
}

export default new CorpActionPlanService();
